@font-face {
    font-family: 'HK Grotesk';
    src: url('../../fonts/hkgrotesk-bold-webfont.woff2') format('woff2'),
         url('../../fonts/hkgrotesk-bold-webfont.woff') format('woff'),
         url('../../fonts/hkgrotesk-bold-webfont.ttf') format('truetype'),
         url('../../fonts/hkgrotesk-bold-webfont.svg#hk_groteskbold') format('svg');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'HK Grotesk';
    src: url('../../fonts/hkgrotesk-light-webfont.woff2') format('woff2'),
         url('../../fonts/hkgrotesk-light-webfont.woff') format('woff'),
         url('../../fonts/hkgrotesk-light-webfont.ttf') format('truetype'),
         url('../../fonts/hkgrotesk-light-webfont.svg#hk_grotesklight') format('svg');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'HK Grotesk';
    src: url('../../fonts/hkgrotesk-regular-webfont.woff2') format('woff2'),
         url('../../fonts/hkgrotesk-regular-webfont.woff') format('woff'),
         url('../../fonts/hkgrotesk-regular-webfont.ttf') format('truetype'),
         url('../../fonts/hkgrotesk-regular-webfont.svg#hk_groteskregular') format('svg');
    font-weight: 400;

    font-style: normal;
}
@font-face {
    font-family: 'nastaliq';
    src: url('../../fonts/fajer-noori-nastalique-regular.woff2') format('woff2'),
         url('../../fonts/fajer-noori-nastalique-regular.woff') format('woff'),
         url('../../fonts/fajer-noori-nastalique-regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@import "../../../../assets/sass/abstracts/variables";

.cursor-reset {
  cursor: default;
}

.blue-bg {
  background-color: $blue-light;
  padding: 15px 6px 6px;
  margin: 0 0px 0 15px;
  position: relative;
  img {
    width: 100%;
  }
}

.info-row {
  display: flex;
  flex-direction: row;
  color: $black;
  margin-bottom: 3px;
  .label-left {
    width: 65px;
    text-align: right;
    margin-right: 10px;
    opacity: .5;
  }
  .label-right {
    text-align: left;
  }
}

.button-double {
  display: flex;
  justify-content: center;
  text-align: center;
  margin-bottom: 10px;
  .btn {
    background-color: $gray-lightest;
    border: 1px solid $gray-lightest;
    margin-right: 10px;
    margin-left: 10px;
    cursor: pointer;
    width: 50%;
    padding: 8px 20px 9px 20px;
    color: $black;
    &.active-correct {
      color: $blue-jeans;
      border: 1px solid $blue-jeans;
      background-color: $white;
      &:hover, &:focus, &:active {
        text-decoration: none;
        color: $blue-jeans;
      }
      i * {
        fill: $blue-jeans;
      }
    }
    &.active-wrong {
      color: $oxford-blue;
      border: 1px solid $oxford-blue;
      background-color: $white;
      &:hover, &:focus, &:active {
        text-decoration: none;
        color: $oxford-blue;
      }
      i * {
        fill: $oxford-blue;
      }
    }
    &:hover, &:focus, &:active {
      text-decoration: none;
      color: $black;
    }
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
    i {
      margin-right: 10px;
      position: relative;
      top: -2px;
      width: 16px;
      svg, img {
        width: auto;
      }
      * {
        fill: $black;
      }
    }
  }
}

.check-list-wrap {
  h3 {
    font-size: 23px;
    color: $gray-dark;
    font-weight: $font-weight-bolder;
    line-height: 1.3;
    text-align: center;
    margin: 0;
    small {
      display: block;
      color: $gray-dark;
      font-weight: $font-weight-base;
      font-size: 16px;
      line-height: 1.3;
      text-align: left;
      margin: 10px 0 0;
    }
  }
  &.wrong {
    h3 {
      color: $oxford-blue;
    }
  }
  &.correct {
    h3 {
      color: $blue-jeans;
    }
  }
}

.target-link {
  display: block;
  background-color: rgba(0, 0, 0, 0);
  position: absolute;
  top: 10px;
  left: 10px;
  width: 114px;
  height: 30px;
  cursor: pointer;
  z-index: 10;
  &.email {
    top: 231px;
    left: 205px;
  }
}

.warning-sign {
  display: block;
  position: absolute;
  top: 177px;
  left: 263px;
  width: 70px;
  z-index: 10;
  &.consent {
    top: 168px;
    left: 210px;
  }
  &.name {
    top: 144px;
    left: 190px;
  }
  &.excel-file {
    top: 445px;
    left: 275px;
  }
  &.reply {
    top: 377px;
    left: 28px;
  }
  &.company-sender {
    top: 145px;
    left: 204px;
  }
  &.company-form {
    top: 512px;
    left: 192px;
  }
  img {
    width: 100%;
  }
}

.attachment-bg {
  cursor: pointer;
  display: block;
  position: absolute;
  width: 181px;
  height: 118px;
  bottom: 40px;
  left: 96px;
  background: url("../../images/attachment.svg") center top no-repeat;
  background-size: 181px;
  &:hover, &:active, &:focus, &.attachment-selected {
    background: url("../../images/attachment-hover.svg") center top no-repeat;
    background-size: 181px;
  }
}

.plus-sign {
  cursor: pointer;
  display: block;
  position: absolute;
  width: 65px;
  left: 10px;
  bottom: 10px;
  img {
    width: 100%;
  }
  &.email-id {
    left: 150px;
    top: 177px;
  }
}

.dropdown-menu {
  position: relative;
  z-index: 20;
  .dropdown-content {
    width: 200px;
    position: absolute;
    top: 35px;
    left: 0;
    color: $black;
    background-color: $white;
    border: 1px solid $oxford-blue;
    padding: 7px 10px;
    box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, .1);
    &.email-info-box {
      width: 325px;
    }
  }
}

.sender {
  top: 180px;
  left: 96px;
  width: 70px;
}

.good-reads {
  top: 185px;
  left: 54px;
  width: 73px;
}

.g-form {
  top: 538px;
  left: 94px;
}

.one-drive {
  top: 518px;
  left: 95px;
  width: 175px;
  height: 40px;
}

.open-doc {
  top: 493px;
  left: 78px;
  width: 125px;
  height: 38px;
}
.cred-cancel {
  top: 402px;
  left: 160px;
  width: 102px;
  height: 40px;
}

@media (max-width: 1110px) {
  .blue-bg {
    img {
      max-width: 95%;
      display: block;
      margin: 0 auto;
    }
  }
  .warning-sign {
    display: block;
    position: absolute;
    top: 28.5%;
    left: 47%;
    width: 70px;
    z-index: 10;
    &.name {
      top: 24%;
      left: 35%;
    }
    &.excel-file {
      top: 73%;
      left: 55%;
    }
    &.consent {
      top: 27%;
      left: 38%;
    }
    &.reply {
      top: 63%;
      left: 6%;
      width: 65px;
    }
    &.company-sender {
      top: 23.5%;
      left: 37.5%;
    }
    &.company-form {
      top: 84%;
      left: 35%;
    }

    img {
      width: 100%;
    }
  }

  .attachment-bg {
    bottom: 28px;
  }

  .g-form {
    bottom: 6%;
    left: 17%;
    width: 110px;
    top: auto;
  }

  .good-reads {
    top: 160px;
    left: 60px;
    width: 73px;
  }

  .one-drive {
    top: auto;
    left: 19.2%;
    width: 177px;
    height: 45px;
    bottom: 5.8%;
  }

  .open-doc {
    top: auto;
    left: 16%;
    width: 130px;
    height: 38px;
    bottom: 11%;
  }

  .cred-cancel {
    top: auto;
    left: 29%;
    width: 107px;
    height: 35px;
    bottom: 27%;
  }
}

@media (max-width: 989px) {
  .blue-bg {
    img {
      max-width: 100%;
    }
  }
  .dropdown-menu {

    .dropdown-content {
      top: 25px;
    }
  }
  .warning-sign {
    display: block;
    position: absolute;
    top: 125px;
    left: 48%;
    width: 50px;
    z-index: 10;
    &.name {
      top: 105px;
      left: 35%;
      width: 50px;
    }
    &.excel-file {
      top: 305px;
      left: 55%;
      width: 50px;
    }
    &.consent {
      top: 118px;
      width: 50px;
    }
    &.reply {
      top: 260px;
      left: 5%;
      width: 50px;
    }
    &.company-sender {
      top: 104px;
      left: 38%;
      width: 50px;
    }
    &.company-form {
      top: 352px;
      left: 36%;
      width: 50px;
    }

    img {
      width: 100%;
    }
  }

  .g-form {
    width: 75px;
    bottom: auto;
    top: 368px;
  }

  .good-reads {
    top: 133px;
    left: 10%;
    width: 54px;
    height: 22px;
  }

  .attachment-bg {
    cursor: pointer;
    display: block;
    position: absolute;
    width: 182px;
    height: 116px;
    bottom: 0;
    left: 47px;
    background: url("../../images/attachment.svg") center top no-repeat;
    background-size: 143px;
    &:hover, &:active, &:focus, &.attachment-selected {
      background: url("../../images/attachment-hover.svg") center top no-repeat;
      background-size: 143px;
    }
  }

  .button-double {

    .btn {
      padding: 8px 7px 9px;
      font-size: 12px;
    }
  }

  .attachment-bg {
    top: 305px;
    bottom: auto;
    background-size: 130px;
  }

  .one-drive {
    bottom: auto;
    top: 361px;
    height: 36px;
    width: 117px;
  }

  .open-doc {
    bottom: auto;
    top: 345px;
    width: 88px;
    height: 30px;
  }

  .cred-cancel {
    width: 75px;
    top: 276px;
    bottom: 283px;
  }

  .button-double {

    .btn {

      i {
        margin-right: 5px;
      }
    }
  }
}

// Make sure to have the file "_variables.scss" at path "assets/sass/abstracts/"
@import "../../../assets/sass/abstracts/variables";

.electricity-page {

  .buttons-list {

    &.expanded {
      max-width: 335px;
      margin: 0 auto;
    }
  }

  .form-box {
    max-width: 300px;
  }
}

.shared-hint {
  color: gray;
  margin-top: 3px;
  font-size: 12px;
  text-align: left;
  padding-left: 3px;
  &.urdu-lang  {
    text-align: right;
  }
  &.danger{
    color: red;
  }
}

.data-counter {
  display: flex;
  padding: 10px 10px 12px;
  border-radius: $border-radius*5;
  font-size: 14px;
  background: $gray-lightest;
  align-items: center;

  @media (max-width: 991px) {
    font-size: 11px;
  }

  .counter {
    display: flex;
  }

  .text-label {
    padding: 0 10px;

    ~ .counter {
      margin-left: auto;
    }
  }

  .count {
    height: 24px;
    width: 24px;
    background: $white;
    border-radius: $border-radius*5;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    text-indent: -9999px;

    &:after,
    &:before {
      content: '';
      position: absolute;
      left: 50%;
      top: 50%;
      background: $black;
      height: 2px;
      width: 12px;
      border-radius: $border-radius;
      transform: translateY(-50%) translateX(-50%);
    }

    &.increment {
      &:before {
        height: 12px;
        width: 2px;
      }
    }

    &:hover {
      background: $blue-jeans;
      color: $white;

      &:after,
      &:before {
        background: $white;
      }
    }
  }

  .text-label {
    text-align: left;
  }

  .input-frame {
    width: 32px;
    padding: 0 3px;

    input {
      width: 100%;
      padding: 0;
      border: 1px solid $blue-jeans;
      border-width: 0 0 1px;
      background: none;
      text-align: center;
      -moz-appearance: textfield;

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      &:focus {
        outline: none;
      }
    }
  }
}

.button-value {
  margin-top: 10px;
  position: relative;
  &:after {
    content: 'Tons';
    position: absolute;
    top: -10px;
    left: 38px;
    font-size: 12px;
  }
  &:before {
    content: 'Qty.';
    position: absolute;
    top: -10px;
    right: 38px;
    font-size: 12px;
  }
}
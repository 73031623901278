//
//  MEDIA QUERIES
//------------------------------------------------------------------
$breakpoints: (
    xxs:    320px,  // Tiny devices (mobile)
    xs:     576px,  // Small tablets and large smartphones (landscape view)
    sm:     768px,  // Tablets (portrait view)
    md:     992px,  // Tablets and small desktops
    lg:     1025px, // Tablets landscape and desktops
    xl:     1200px, // Desktops
    xxl:    1300px  // Desktops
);

//
//  RESPOND UP
//------------------------------------------------------------------
// @include media-up(sm) {...}
@mixin media-up($breakpoint) {

    // If the breakpoint exists in the map.
    @if map-has-key($breakpoints, $breakpoint) {

        // Get the breakpoint value.
        $breakpoint-value: map-get($breakpoints, $breakpoint);

        // Write the media query.
        @media (min-width: $breakpoint-value) {
            @content;
        }

        // If the breakpoint doesn't exist in the map.
    } @else {

        // Log a warning.
        @warn 'Invalid breakpoint: #{$breakpoint}.';
    }
}

//
//  RESPOND DOWN
//------------------------------------------------------------------
// @include media-down(sm) {...}
@mixin media-down($breakpoint) {

    // If the breakpoint exists in the map.
    @if map-has-key($breakpoints, $breakpoint) {

        // Get the breakpoint value.
        $breakpoint-value: map-get($breakpoints, $breakpoint);

        // Write the media query.
        @media (max-width: ($breakpoint-value - 1)) {
            @content;
        }

        // If the breakpoint doesn't exist in the map.
    } @else {

        // Log a warning.
        @warn 'Invalid breakpoint: #{$breakpoint}.';
    }
}

//
//  RESPOND BETWEEN
//------------------------------------------------------------------
// @include media-between(sm, md) {}
@mixin media-between($lower, $upper) {

    // If both the lower and upper breakpoints exist in the map.
    @if map-has-key($breakpoints, $lower) and map-has-key($breakpoints, $upper) {

        // Get the lower and upper breakpoints.
        $lower-breakpoint: map-get($breakpoints, $lower);
        $upper-breakpoint: map-get($breakpoints, $upper);

        // Write the media query.
        @media (min-width: $lower-breakpoint) and (max-width: ($upper-breakpoint - 1)) {
            @content;
        }

        // If one or both of the breakpoints don't exist.
    } @else {

        // If lower breakpoint is invalid.
        @if (map-has-key($breakpoints, $lower) == false) {

            // Log a warning.
            @warn 'Your lower breakpoint was invalid: #{$lower}.';
        }

        // If upper breakpoint is invalid.
        @if (map-has-key($breakpoints, $upper) == false) {

            // Log a warning.
            @warn 'Your upper breakpoint was invalid: #{$upper}.';
        }
    }
}

/*!
Main styles for Edly Panel
*/
@import "./abstracts/mixins";
@import "./abstracts/variables";
@import "./abstracts/breakpoints";
@import "./abstracts/fonts";
@import "./abstracts/forms";
@import "./components/custom-rage";
@import "./components/button-list";
@import "./components/layout";
@import "./reboot";

h1 {
  font-size: 30px;
}

h3 {
  font-size: 1rem;
}

body {
  min-width: 320px;
}

.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main {
  padding: 25px 15px;

  .container {
    max-width: $main-container-width;
    margin: 0 auto;
  }
}

.disabled-link {
  pointer-events: none;
}

.title-case {
  text-transform: capitalize;
}

.highcharts-yaxis-labels {
  visibility: hidden;
}

.box {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 5px;
  border: 1px solid rgba(0, 0, 0, .2);
  vertical-align: middle;
}

.relative {
  background: #C6FBBC;
}

.minimal {
  background: #FFF795;
}

.significant {
  background: #F8AF85;
}

.serious {
  background: #F27070;
}

.yAxis-labels {
  text-align: center;
  margin-top: 20px;
}

/***************************************************/
//
// Media Queries
//
/***************************************************/

@media (max-width: 767px) {

  .hidden-mobile {
    display: none;
  }
}

@media (min-width: 768px) {

  .main {
    flex-grow: 1;
  }
}

.name-position {
  display: flex;
  justify-content: center;
  .name-ur {
    order: 1;
    margin-left: 5px;
  }
}

.rtl {
  text-align: right;
}

// ----------------------------------------------------------------------------
// Defualt form field styles
// ----------------------------------------------------------------------------

// Default variables to set styles for User Account Form
$input-color:                   #000 !default;
$input-bg:                      #f9f9f9 !default;
$input-border-color:            #ccc !default;
$input-disabled-bg:             #ddd !default;
$input-placeholder-color:       lighten($input-color, 15%) !default;
$input-focus-border-color:      #0060c7 !default;
$error-color:                   #dc3545 !default;
$success-color:                 #28a745 !default;
$border-radius:                 4px !default;
$input-font-family:             Arial, "Helvetica Neue", Helvetica, sans-serif !default;

.form-control {
    display: block;
    width: 100%;
    height: 43px;
    padding: 5px 12px 7px;
    font-family: $input-font-family;
    font-size: 16px;
    font-weight: 400;
    line-height: 2.25;
    color: $input-color;
    background: $input-bg;
    border: 2px solid $input-border-color;
    box-shadow: none;
    border-radius: $border-radius;

    &.small {
        height: 38px;
        line-height: 1.5;
    }

    &:focus,
    &.input-focused {
        outline: none;
        border-color: $input-focus-border-color;

        ~ .input-label {
            font-size: 14px;
            top: -12px;
            color: $input-focus-border-color;
        }
    }

    &:-ms-expand {
        background-color: transparent;
        border: 0;
    }

    &:placeholder {
        color: $input-placeholder-color;
        opacity: 1;
    }

    &:disabled,
    &[readonly] {
        background-color: $input-disabled-bg;
        opacity: 1;
        pointer-events: none;
    }

    &.select {
        width: auto;
        display: inline-block;
    }

    .error & {
        outline: none;
        border-color: $error-color;
        box-shadow: 0 0 0 2px rgba($error-color, .5);
    }

    .success & {
        outline: none;
        border-color: $success-color;
        box-shadow: 0 0 0 2px rgba($success-color, .5);
    }
}

.form-box {
    position: relative;
    max-width: 400px;
    margin: 0 auto;
}

.input-label {
    font-size: 16px;
    color: $input-border-color;
    position: absolute;
    top: 7px;
    left: 11px;
    padding: 0 5px;
    background: $white;
    pointer-events: none;
    transition: all .3s ease;
}

.form-label {
    font-size: 20px;
    text-align: center;
    display: block;

    .form-row & {
        margin-bottom: 20px;
    }
}

.text {
    font-size: 14px;

    &-error {
        color: $error-color;
    }

    &-success {
        color: $success-color;
    }
}

// ----------------------------------------------------------------------------
// Search Bar Styles
// ----------------------------------------------------------------------------

.search-bar {
    display: flex;
    flex: 1;

    .form-control {
        margin-right: -1px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    .btn {
        height: 100%;
        min-width: 60px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
}

// ----------------------------------------------------------------------------
// Select Box Styles
// ----------------------------------------------------------------------------

.select-box {
    position: relative;

    &:after {
        background: $white;
        border-color: $input-focus-border-color;
    }
}

.roles-row {
    padding-left: 20px;
}

// ----------------------------------------------------------------------------
// Custom Checkbox
// ----------------------------------------------------------------------------

.custom-control-input {
    position: absolute;
    z-index: -1;
    opacity: 0;

    &:checked {

        ~ .custom-control-label {
            background: $blue-jeans;
            color: $white;

            .a{
                fill: $white;
            }

            svg path {
                fill: $white;
            }
        }
    }
}

.custom-control-label {
    padding: 0;
    margin: 0;
    min-height: 24px;
    position: relative;
    font-weight: 400;
    background: $gray-lightest;
    cursor: pointer;

    &:hover {
        background: $blue-jeans;
        color: $white;

        .a{
            fill: $white;
        }

        svg path {
            fill: $white;
        }
    }

    .text-info {
        opacity: .4;
        font-weight: 500;
        display: block;
        margin: -3px 0 0;
        font-size: 1.3rem;
    }
}

// ----------------------------------------------------------------------------
// Custom Radios
// ----------------------------------------------------------------------------

.custom-control-label {

    &:after,
    &:before {

        .custom-radio & {
            top: 5px;
            border-radius: 50%;
        }
    }

    &:after {

        .custom-radio & {
            top: 9px;
            left: 4px;
            width: 10px;
            height: 10px;
            background: $brand-primary;
            border: 0;
            z-index: 1;
        }
    }
}

// ----------------------------------------------------------------------------
// Custom Radios
// ----------------------------------------------------------------------------

.custom-file {
    position: relative;

    .form-control {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        border: 0;
        background: none;
        font-size: 0;
        text-indent: -50px;
        cursor: not-allowed;

        &:not(:disabled) {
            cursor: pointer;

            &:hover,
            &:focus,
            &:active {

                ~ .custom-file-label {
                    background: $white;
                }
            }
        }
    }

    .custom-file-label {
        background: rgba($brand-primary, .05);
        color: $brand-primary;
        border: 1px dashed;
        padding: 10px 10px 12px;
        display: block;
        text-align: center;
        font-weight: 400;
        border-radius: $border-radius;
        cursor: pointer;
    }
}

.input-info {
    font-size: 14px;
    display: block;
}

/***************************************************/
//
// Media Queries
//
/***************************************************/

@media (min-width: 560px) {

    .roles-row {
        display: flex;
        padding-left: 0;

        .custom-control {
            margin-right: 35px;

            &:last-child {
                margin-right: 0;
            }
        }
    }
}

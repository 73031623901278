@import "../../../../assets/sass/abstracts/variables";

.info-box-wrap {
  width: 1050px;
  margin: 0 auto;
  background-color: $white;
  border: 2px solid $gray-border;
  padding: 15px 0;
  outline: none;
  :focus, :active {
    outline: none;
  }
}

.row {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;

  [class^="col-"] {
    flex: 0 0 auto;
    padding-right: 15px;
    padding-left: 15px;
  }
  .col-image {
    flex-basis: 590px;
    max-width: 590px;
  }
  .col-info {
    position: relative;
    padding-bottom: 120px;
    flex-basis: 450px;
    max-width: 450px;
    padding-left: 5px;
    padding-right: 22px;
  }
}

.col-image {
  .bg-position {
    padding-left: 15px;
    width: 100%;
  }
}

.heading-h2 {
  font-size: 21px;
  font-weight: $font-weight-bolder;
  line-height: 1.3;
  margin: 0 0 10px 0;
  &.rtl {
    line-height: 1.8;
  }
}

.help-text {
  font-size: 16px;
  font-weight: $font-weight-base;
  line-height: 1.3;
}

.image-wrap {
  position: relative;
  cursor: url("../../images/mouse-cursor.svg") 35 15, auto;
  span {
    cursor: url("../../images/mouse-cursor.svg") 35 15, auto;
  }
}

.user-position {
  position: absolute;
  right: 64px;
  top: 168px;
  z-index: 9;
  &.position-correct {
    top: 149px;
  }
}

.user-arms-screen {
  position: absolute;
  right: 64px;
  top: 148px;
  z-index: 9;
  &.position-correct {
    top: 149px;
  }
}

.user-keyboard-on-table {
  position: absolute;
  right: 103px;
  top: 148px;
  z-index: 9;
  &.position-correct {
    top: 149px;
  }
}

.user-feet-screen {
  position: absolute;
  right: 103px;
  top: 150px;
  z-index: 9;
  &.position-correct {
    top: 149px;
  }
}

.pc-position {
  position: absolute;
  left: 56px;
  top: 161px;
  z-index: 9;
}

.pc-position-up {
  position: absolute;
  left: 56px;
  top: 136px;
  z-index: 9;
}

.fix-style {
  display: block;
  position: absolute;
  z-index: 10;
  &:after {
    content: '';
    display: block;
    position: relative;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.7s linear;
  }
  &:hover {
    &:after {
      visibility: visible;
      opacity: 1;
    }
  }
  img {
    width: 58px;
    height: 58px;
  }
}

.back-pain {
  display: block;
  position: absolute;
  left: 388px;
  top: 185px;
  z-index: 10;
  img {
    width: 100%;
  }
}

.back-pain {
  left: 389px;
  top: 238px;
  width: 58px;
  height: 58px;
  &:after {
    background: url("../../images/thunder.svg") 10px 10px no-repeat transparent;
    right: -13px;
    top: -120px;
    width: 90px;
    height: 100px;
  }
}

.back-pain-clicked {
  left: 389px;
  top: 238px;
  width: 58px;
  height: 58px;
  &:after {
    background: url("../../images/back-pain-clicked.svg") 10px 10px no-repeat transparent;
    right: -11px;
    top: -80px;
    width: 65px;
    height: 120px;
    visibility: visible;
    opacity: 1;
  }
}

.elbow {
  left: 389px;
  top: 293px;
  width: 58px;
  height: 58px;
  transform: rotate(100deg);
  &:after {
    background: url("../../images/thunder.svg") 10px 10px no-repeat transparent;
    right: -13px;
    top: -120px;
    width: 90px;
    height: 100px;
  }
}

.elbow-clicked {
  left: 392px;
  top: 296px;
  width: 58px;
  height: 58px;
  &:after {
    background: url("../../images/elbow-clicked.svg") 10px 10px no-repeat transparent;
    right: 39px;
    top: -61px;
    width: 120px;
    height: 90px;
    visibility: visible;
    opacity: 1;
  }
}

.neck {
  left: 133px;
  top: 180px;
  width: 58px;
  height: 58px;
  &:after {
    background: url("../../images/neck-thunder.svg") 10px 10px no-repeat transparent;
    right: -30px;
    top: -107px;
    width: 90px;
    height: 100px;
  }
}

.neck-clicked {
  left: 133px;
  top: 180px;
  width: 58px;
  height: 58px;
  &:after {
    background: url("../../images/neck-clicked.svg") 10px 10px no-repeat transparent;
    left: 40px;
    top: -48px;
    width: 140px;
    height: 20px;
    visibility: visible;
    opacity: 1;
  }
}

.rule-20 {
  left: 144px;
  top: 205px;
  width: 58px;
  height: 58px;
  &:after {
    background: url("../../images/neck-thunder.svg") 10px 10px no-repeat transparent;
    right: -30px;
    top: -107px;
    width: 90px;
    height: 100px;
  }
}

.rule-20-clicked {
  left: 135px;
  top: 225px;
  width: 58px;
  height: 58px;
  &:after {
    background: url("../../images/rule-20-clicked.svg") 10px 10px no-repeat transparent;
    right: -30px;
    top: -133px;
    width: 50px;
    height: 160px;
    visibility: visible;
    opacity: 1;
  }
}

.feet {
  right: 262px;
  bottom: 57px;
  width: 58px;
  height: 58px;
  transform: rotate(115deg);
  &:after {
    background: url("../../images/thunder.svg") 10px 10px no-repeat transparent;
    right: -13px;
    top: -120px;
    width: 90px;
    height: 100px;
  }
}

.feet-clicked {
  right: 262px;
  bottom: 57px;
  width: 58px;
  height: 58px;
  &:after {
    background: url("../../images/feet-clicked.svg") 10px 10px no-repeat transparent;
    right: 32px;
    bottom: 35px;
    width: 140px;
    height: 60px;
    visibility: visible;
    opacity: 1;
  }
}

.btn-center {
  text-align: center;
  margin-top: 30px;
}

.row {

  .col-info {

    .btn-center {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 72px;
    }
  }
}

.final-message {
  padding: 52px 20px 80px;
  position: relative;
  text-align: center;
  font-size: 16px;
  line-height: 21px;

  h3 {
    font-size: 20px;
    line-height: 26px;
    margin: 0 0 10px;
  }

  p {
    margin: 0 0 50px;
  }

  img {
    display: block;
    max-width: 100%;
    height: auto;
    margin: 0 auto 10px;
  }

  .info {
    font-size: 14px;
    line-height: 18px;
    font-weight: bold;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 5px;
  }
}

@media (max-width: 1110px) {
  .info-box-wrap {
    width: 100%;
    max-width: 1050px;
  }

  .row {

    .col-image {
      flex-basis: inherit;
      max-width: inherit;
      width: 550px;
    }

    .col-info {
      flex-basis: inherit;
      max-width: inherit;
      width: calc(100% - 565px);
    }

    .sender {
      top: 29.5%;
      left: 19%;
    }

    .target-link {

      &.email {
        top: 37%;
        left: 38%;
        width: 100px;
      }
    }
  }

  .user-position,
  .user-arms-screen {
    right: auto;
    left: 44%;
  }

  .pc-position {
    left: 11%;
  }

  .back-pain {
    left: auto;
    top: 40%;
    right: 12%;
  }

  .back-pain-clicked {
    &:after {
      right: auto;
      left: 25%;
      top: -140%;
    }
  }

  .elbow {
    left: 74%;
    top: 54%;
  }

  .elbow-clicked {
    &:after {
      right: auto;
      left: -56%;
    }
  }

  .user-keyboard-on-table,
  .user-feet-screen {
    right: 11%;
  }

  .neck {
    left: 29%;
    top: 32%;
  }

  .neck-clicked {
    &:after {
      left: 63%;
    }
  }

  .feet {
    bottom: -1%;
    right: 39%;
  }

  .feet-clicked {
    &:after {
      right: auto;
      left: 40%;
      bottom: -5px;
      width: 112px;
      transform: rotate(-4deg);
    }
  }

  .rule-20 {
    left: 32%;
    top: 35%;
  }

  .row {

    .col-info {

      .btn-center {
        bottom: 65px;
      }
    }
  }
}

@media (max-width: 989px) {
  .image-wrap {
    display: flex;
    height: 100%;

    &.ergonomics {
      height: auto;
      display: block;
      align-items: flex-start;
    }
  }

  .feet-clicked {
    bottom: 18px;
  }

  .row {

    .col-image {
      flex-basis: inherit;
      max-width: inherit;
      width: 420px;
    }

    .col-info {
      flex-basis: inherit;
      max-width: inherit;
      width: calc(100% - 435px);
    }

    .sender {
      top: 127px;
      left: 17%;
    }

    .target-link {

      &.email {
        top: 160px;
        left: 37%;
        width: 65px;
      }
    }
  }

  .pc-position,
  .pc-position-up {
    left: 40px;
    top: 110px;
    max-width: 150px;
  }

  .pc-position-up {
    top: 96px;
  }

  .user-position,
  .user-arms-screen,
  .user-keyboard-on-table,
  .user-feet-screen {
    right: 55px;
    top: 122px;
    max-width: 170px;
  }

  .user-feet-screen {
    right: 74px;
    top: 110px;
    max-height: 260px;
  }

  .user-keyboard-on-table {
    right: 74px;
    top: 107px;
  }

  .user-arms-screen {
    top: 110px;
  }

  .back-pain {
    left: 280px;
    top: 155px;
    width: 40px;
    height: 50px;
  }

  .back-pain-clicked {
    &:after {
      right: 107px;
      left: auto;
      top: -145px;
      width: 45px;
      height: 60px;
      background-size: contain;
    }
  }

  .heading-h2 {
    font-size: 19px;
  }

  .select-heading {
    font-size: 18px;
  }

  .elbow {
    left: 259px;
    top: 200px;

    &:after {
      right: -3px;
      top: -90px;
    }
  }

  .elbow-clicked {
    &:after {
      right: 168px;
      top: -170px;
      left: auto;
    }
  }

  .neck {
    left: 103px;
    top: 127px;
    &:after {
      right: -13px;
      top: -95px;
    }
  }

  .neck-clicked {
    &:after {
      left: -18px;
      width: 100px;
      top: -110px;
    }
  }

  .feet {
    bottom: 20px;
    right: 162px;
    &:after {
      right: 7px;
      top: -80px;
    }
  }

  .feet-clicked {
    &:after {
      right: -80px;
      bottom: 75px;
      left: auto;
      transform: rotate(-7deg);
    }
  }

  .rule-20 {
    left: 112px;
    top: 128px;
    &:after {
      right: 0;
      top: -83px;
    }
  }

  .rule-20-clicked {
    &:after {
      right: 25px;
      top: -176px;
      width: 45px;
      height: 95px;
      background-size: contain;
    }
  }

  .row {

    .col-info {

      .btn-center {
        bottom: 40px;
      }
    }
  }
}

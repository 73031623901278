@import "../../../../assets/sass/abstracts/variables";

.card-wrap {
  margin: 20px auto 50px auto;
}

.card {
  display: inline-block;
  margin-right: 40px;
  cursor: pointer;
  text-align: center;
  cursor: pointer;
  &:last-child {
    margin-right: 0;
  }

  span {
    display: block;
    background-color: $white;
    border-radius: 20px;
    padding: 35px 35px 30px 35px;
    svg, img {
      width: 150px;
      * {
        fill: $gray-icon;
      }
    }
    p {
      font-size: 21px;
      font-weight: $font-weight-base;
      line-height: $line-height-base;
      display: block;
      margin: 10px 0 0 0;
      color: $gray-lighter;
      outline: none;
    }
    &:active, &:focus {
      p {
        outline: none;
        text-decoration: none;
      }
    }
    &:hover {
      outline: none;
      background-color: $blue-jeans;
      text-decoration: none;
      p {
        outline: none;
        text-decoration: none;
        color: $white;
      }
      svg, img {
        * {
          fill: $white;
        }
      }
    }
  }
  &.active {
    span {
      outline: none;
      background-color: $blue-jeans;
      text-decoration: none;
      p {
        outline: none;
        text-decoration: none;
        color: $white;
      }
      svg, img {
        * {
          fill: $white;
        }
      }
    }
  }
}
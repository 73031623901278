@import "../../../assets/sass/abstracts/variables";

.borderstyle {
  visibility: visible;
}

.graph-heading {
  width: 250px;
  &.carbon-ur {
    text-align: left;
  }
  &.water-ur {
    text-align: right;
  }
  &.carbon-en {
    text-align: center;
  }
  &.water-en {
    text-align: center;
  }
}

.footprint-block {
  display: flex;
  align-items: flex-end;
  max-width: 900px;
  margin: 0 auto;
  font-size: 9px;
  padding-bottom: 25px;

  .column {
    flex-grow: 1;
    text-align: center;
  }

  img {
    max-width: 100%;
    margin: 0 auto;
  }

  .recharts-layer {

    &.yAxis {
      .recharts-cartesian-axis-tick {
        display: none;
      }
    }
  }

  .recharts-wrapper {
    margin: 0 auto;
  }
}


// Make sure to have the file "_variables.scss" at path "assets/sass/abstracts/"
@import "../../../assets/sass/abstracts/variables";

.footer {
    text-align: center;
    padding: 0 3% 25px;
    margin-top: auto;

    .footer-logo {
        display: inline-block;
        max-width: 185px;

        img {
            max-width: 100%;
        }
    }
}

.footer-buttons {
    text-align: center;
    padding-bottom: 20px;

    .btn {
        margin: 0 5px;
        color: #FFF !important;
    }
}

.close-text {
    padding-top: 10px;
    font-weight: bold;
    text-align: center;
}

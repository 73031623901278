//
// Colors
//
$gray-lightest:                     #ecf0f4;
$gray-lighter:                      #c5cfd6;
$gray-light:                        #d7d7d7;
$gray-dark:                         #292c2e;
$gray-darker:                       #464040;
$gray-icon:                         #dde3e9;
$gray-border:                       #f0f0f0;
$red:                               #dd1f25;
$red-dark:                          #ab181d;
$black:                             #000;
$white:                             #fff;
$pink:                              #f66e7e;
$blue-light:                        #c6f2f7;
$green:                             #62d586;
$green-alt:                         #aad7cd;
$yellow:                            #ffe15e;
$darkcyan:                          #69a1ac;
$blue-jeans:                        #56B7F7;
$oxford-blue:                       #11294A;
$oxford-blue-dark:                  #002147;
$primamry-blue:                     #C7F3FF;

$brand-primary:                     $oxford-blue;
$brand-primary-dark:                $oxford-blue-dark;

$error:                             $oxford-blue-dark;
$default:                           $gray-darker;
$warning:                           $yellow;
$success:                           $green;

$default-radius:                    6px;

$border-radius:                     $default-radius;

// Typography
//
// Font, line-height, and color for body text, headings, and more.

// $font-family-sans-serif:            "HK Grotesk", "Helvetica Neue", Helvetica, Arial, sans-serif;
$font-family-sans-serif:            "HK Grotesk", "nastaliq", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
$font-family-monospace:             "Liberation Mono", "Courier New", monospace;
$font-family-base:                  $font-family-sans-serif;
$font-size-base:                    14px;
$font-weight-base:                  400;
$line-height-base:                  1.5;
$body-color:                        $gray-dark;
$body-bg:                           $white;
$headings-margin-bottom:            1.2rem;
$paragraph-margin-bottom:           1.2rem;
$dt-font-weight:                    600;
$font-weight-bolder:                600;
$link-color:                        $brand-primary;
$link-hover-color:                  darken($link-color, 15%);
$link-decoration:                   none;
$link-hover-decoration:             underline;

$table-cell-padding:                10px;
$table-caption-color:               $gray-darker;
$label-margin-bottom:               0.3rem;
$label-font-weight:                 700;

$main-container-width:              1200px;

// Froms
//
// Define footer colors.
$input-bg:                          $white;
$input-border-color:                $gray-lighter;
$input-disabled-bg:                 #ddd;
$input-focus-border-color:          $blue-jeans;
$input-error-border-color:          $error;
$input-success-border-color:        $success;
$input-font-family:                 $font-family-sans-serif;

$btn-color:                         $gray-darker;
$btn-bg:                            #f1f1f1;
$btn-primary-color:                 $white;
$btn-primary-bg:                    $brand-primary;

.icon-box {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;

  .icon-wrap {
    margin-right: 25px;
  }

  &.rtl {
    text-align: right;
    .icon-wrap {
      margin-left: 25px;
      margin-right: 0;
      order: 2;
    }
  }

}

.action-block {
  h2 {
    text-align: center;
  }

  ol {
    font-weight: bold;

    li {
      margin-bottom: 5px;
    }

    .action-text {
      font-weight: normal;
      margin: 0;
    }
  }
}

.buttons-list {
    @include list();
    @include clearfix;
    margin: 0 -15px;
    text-align: center;

    li {
        display: inline-block;
        vertical-align: top;
        width: calc(33.333% - 20px);
        margin: 0 10px 20px;
        &.four-opt {
            width: calc(25% - 20px);
        }
    }

    label {
        height: 43px;
        display: flex;
        align-items: center;
        padding: 4px 18px;
        border-radius: $border-radius * 20;

        @include media-down(md) {
            padding: 4px 10px;
        }

        .image-box {
            width: 60px;
            text-align: center;
            padding: 5px;
        }

        .text {
            border-left: 1px solid $gray-lighter;
            padding-left: 6%;
            margin-left: 7%;

            @include media-down(md) {
                padding-left: 4%;
                margin-left: 4%;
                line-height: 1.3;
            }
        }
    }


    &.expanded {
        margin-left: 0;
        margin-right: 0;

        li {
            display: block;
            width: auto;
            margin-left: 0;
            margin-right: 0;
        }
    }

    button {
        width: 100%;
    }
}

// Make sure to have the file "_variables.scss" at path "assets/sass/abstracts/"
@import "../../../assets/sass/abstracts/variables";

.navigation {

    ul {
        margin: 0;
        padding: 0;
        list-style: none;
        display: table;
        table-layout: fixed;
        width: 100%;
        text-align: center;
        background: $gray-lightest;

        li {
            display: table-cell;
            vertical-align: top;

            &:first-child {

                a {
                    &:before {
                        display: none;
                    }
                }
            }
        }

        a {
            display: block;
            font-size: 12px;
            color: $gray-dark;
            position: relative;
            padding: 20px 0 20px 20px;

            @media (max-width: 991px) {
                font-size: 11px;
            }

            &:after,
            &:before {
                content: '';
                position: absolute;
                height: 100%;
                width: 20px;
                top: 0;
                background-image: url('../../../assets/images/nav-gray-arrow.png');
                background-size: 100% 100%;
            }

            &:before {
                left: 0;
            }

            &:after {
                left: 100%;
                z-index: 1;
                background-image: url('../../../assets/images/nav-blue-arrow.png');
                display: none;
            }

            &:hover,
            &.active {
                background: $blue-light;
                text-decoration: none;

                &:after {
                    display: block;
                }

                .img {
                    opacity: 0;
                }

                .img-hover {
                    opacity: 1;
                }
            }
        }


        .link-text {
            display: block;
            padding: 10px 0 0;
        }

        .img-box {
            position: relative;
            display: inline-block;
            vertical-align: top;
            max-width: 63px;

            img {
                max-width: 100%;
            }

            span {
                display: block;
            }

            svg {
                width: 100%;
                height: auto;
            }

            .img-hover {
                position: absolute;
                top: 0;
                left: 0;
                opacity: 0;
            }
        }
    }
}

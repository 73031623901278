// Make sure to have the file "_variables.scss" at path "assets/sass/abstracts/"
@import "../../../assets/sass/abstracts/mixins";
@import "../../../assets/sass/abstracts/variables";

.default-wrapper {
    max-width: 650px;
    margin: 0 auto;
    padding-top: 25px;

    &.four-opt-wrapper  {
        max-width: 990px;
        margin: 0 auto;
        padding-top: 25px;

        .highcharts-yaxis-grid{
            visibility: hidden;
        }
    }

    &.appliances,
    &.plastic-page {
        max-width: 885px;
    }

    &.start-page {
        max-width: 500px;
    }

    &.footprint {
        text-align: center;
    }

    .form-row {
        margin-bottom: 30px;

        h2 {
            text-align: center;
        }

        &:after {
            content: '';
            display: table;
        }
    }
}

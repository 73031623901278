// Make sure to have the file "_variables.scss" at path "assets/sass/abstracts/"
@import "../../../assets/sass/abstracts/variables";

.landing {
    background: $blue-light;
    padding: 0 20px;

    .w1 {
        flex-grow: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 20px 0;
    }

    .logo {
        display: block;
        margin: 0 auto;
        max-width: 130px;

        svg {
            width: 100%;
            height: auto;
        }
    }

    h1 {
        font-size: 26px;
        text-transform: uppercase;
        &.rtl {
            margin-top: 10px;
        }
    }

    .text-frame {
        background: $white;
        border-radius: $border-radius + 2;
        padding: 20px;
        margin-bottom: 10px;

        .scroll {
            height: 144px;
            overflow-y: auto;

            .terms-conditions {
                white-space: pre-wrap;
                padding-top: 5px;
                &.rtl {
                    line-height: 1.8;
                }
            }
        }
    }

    .text-area {
        max-width: 992px;
        width: 100%;
    }

    .text-label {
        position: relative;
        padding-left: 33px;
        margin-bottom: 20px;

        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            height: 21px;
            width: 21px;
            border-radius: $border-radius;
            background: $blue-jeans;
        }

        &:after {
            content: '';
            position: absolute;
            top: 6px;
            left: 4px;
            height: 6px;
            width: 12px;
            border: 1px solid $white;
            border-width: 0 0 1px 1px;
            transform: rotate(-45deg);
        }
    }

    .urdu-lang-terms {
        text-align: right;
        padding-left: 33px;
        position: relative;
        margin-bottom: 20px;
        padding-right: 30px;

        &:before {
            content: '';
            position: absolute;
            top: 2px;
            right: 0;
            height: 21px;
            width: 21px;
            border-radius: $border-radius;
            background: $blue-jeans;
        }

        &:after {
            content: '';
            position: absolute;
            top: 8px;
            right: 4px;
            height: 6px;
            width: 12px;
            border: 1px solid $white;
            border-width: 0 0 1px 1px;
            transform: rotate(-45deg);
        }
    }

    .text-powered {
        font-size: 7px;
        display: inline-block;
        vertical-align: top;
        padding: 5px;
    }

    .footer-logo {
        max-width: 74px;
    }
}

// Make sure to have the file "_variables.scss" at path "assets/sass/abstracts/"
@import "../../assets/sass/abstracts/variables";

.btn {
    text-align: center;
    padding: 4px 20px 5px;
    border: 1px solid #000;
    display: inline-block;
    vertical-align: top;
    border-radius: $border-radius;
    text-transform: uppercase;
    min-width: 115px;

    &:focus {
        outline: none;
    }

    &-primary {
        background: $oxford-blue;
        color: $white;
        border-color: $oxford-blue;
        text-align: center;

        &:hover {
            color: $white;
            background: darken($oxford-blue, 10%);
            border-color: darken($oxford-blue, 10%);
            text-decoration: none;
        }

        &.disabled,
        &:disabled {
            background: #f1f1f1;
            border-color: #f1f1f1;
            color: #000;
            cursor: default;
            pointer-events: none;
        }
    }

    &-secondary {
        text-align: center;
        padding: 10px 20px 12px;
        border: 0;
        display: inline-block;
        vertical-align: top;
        border-radius: $border-radius*5;
        min-width: 115px;
        font-size: 14px;

        &:hover,
        &.active {
            background: $blue-jeans;
            color: $white;
        }

        &:focus {
            outline: none;
        }
    }
}

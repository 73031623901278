// Make sure to have the file "_variables.scss" at path "assets/sass/abstracts/"
@import "../abstracts/variables";

.wrapper {

    .rangeslider {
        background: $gray-lighter;
        box-shadow: none;
        margin-top: 30px;
    }

    .rangeslider-horizontal {
        height: 1px;
        background: $gray-lighter;

        .rangeslider__fill {
            background: none;
            box-shadow: none;
        }

        .rangeslider__handle {
            background: $blue-jeans;
            height: 26px;
            width: 26px;
            box-shadow: 0 3px 6px rgba($black, .17);
            border: 0;

            &:after {
                display: none;
            }
        }
    }
}

.step-count {
    position: fixed;
    right: 0;
    bottom: 0;
    color: $blue-jeans;
    font-size: 15px;
    padding: 15px;
}

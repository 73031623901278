body {
  overflow-x: hidden;
}

.break-block {
  font-size: 20px;
  text-align: center;

  h2 {
    font-size: 26px;
  }
}

.water-bg-back {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: url('../../assets/images/wave-second.svg') no-repeat center bottom;
  background-size: 200% auto;
  z-index: -1;
  animation: wave 40s infinite;
  -moz-animation: wave 40s infinite;
  -webkit-animation: wave 40s infinite;
}

.water-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url('../../assets/images/wave-front.svg') no-repeat center bottom;
  background-size: 200% auto;
  z-index: -2;

  animation: move 40s infinite;
  -moz-animation: move 40s infinite;
  -webkit-animation: move 40s infinite;

}

.wave-first {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;

  animation: wave 20s infinite;
  -moz-animation: wave 20s infinite;
  -webkit-animation: wave 20s infinite;

  img {
    position: absolute;
    bottom: -174px;
    left: -150px;
    width: 300%;
  }
}

.wave-second {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;

  animation: move 15s infinite;
  -moz-animation: move 15s infinite;
  -webkit-animation: move 15s infinite;

  img {
    position: absolute;
    bottom: -270px;
    left: -150px;
    width: 300%;
  }
}

/*Water Wave Animation*/

/* Wave 1*/
@keyframes wave {
  0% {
    left: 0%;
    bottom: 0;
  }

  50% {
    left: -90%;
    bottom: 0;
  }

  100% {
    left: 0%;
    bottom: 0;
  }
}

/* Wave 1*/

/* Wave 2*/
@keyframes move {
  0% {
    left: -50%;
    bottom: 0;
  }

  50% {
    left: -180%;
    bottom: 0;
  }

  100% {
    left: -50%;
    bottom: 0;
  }
}

/* Wave 2*/
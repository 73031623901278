// List @include list();
@mixin list() {
    margin: 0;
    padding: 0;
    list-style: none;
}

// Placeholder @include placeholder(red);
@mixin placeholder($color) {

  &:-moz-placeholder {
    color: $color;
  }

  &::-webkit-input-placeholder {
    color: $color;
  }

  &:-ms-input-placeholder {
     color: $color;
  }
}

// clearfix , @include clearfix();
@mixin clearfix() {
    &:before,
    &:after {
        content: "";
        display: table;
    }
    &:after {
        clear: both;
    }
}

// example: @include ellipsis(300px);
@mixin ellipsis($max-width: 100%){
  display: inline-block;
  max-width: $max-width;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

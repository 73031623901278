@import "../../../../assets/sass/abstracts/variables";

.check-list-wrap {
  background-color: $white;
  border-radius: 8px;
  box-shadow: 0 0 16px 0 rgba($black, .1);
  padding: 18px;
  margin-top: 25px;
}

.select-heading {
  font-size: 20px;
  line-height: 1.3;
  color: $gray-dark;
  font-weight: $font-weight-bolder;
  margin: 0 0 20px 0;
}

.select-item {
  list-style: none;
  margin: 0;
  padding: 0;
  font-weight: $font-weight-base;
  font-size: 17px;
  cursor: pointer;
  li {
    margin: 0 0 10px 0;
    padding: 0;
    &:last-child {
      margin: 0;
    }
    span {
      background: url("../../images/select-blank.svg") 15px center no-repeat $gray-lightest;
      padding: 15px 15px 15px 50px;
      background-size: 25px;
      line-height: 1.3;
      border: 1px solid $gray-lightest;
      display: block;
      border-radius: 8px;
      color: $gray-dark;
      text-decoration: none;
      cursor: pointer;
      outline: none;
      &.wrong {
        background: url("../../images/select-wrong.svg") 15px center no-repeat $white;
        border: 1px solid $oxford-blue;
        background-size: 25px;
      }
      &.correct {
        background: url("../../images/select-correct.svg") 15px center no-repeat $white;
        border: 1px solid $blue-jeans;
        background-size: 25px;
      }
      .rtl & {
        padding: 15px 55px 15px 15px;
        background-position: calc(100% - 15px) center;

        &.wrong {
          background-position: calc(100% - 15px) center;
        }
        &.correct {
          background-position: calc(100% - 15px) center;
        }
      }
    }
  }
}

.select-footnote {
  border-top: 1px solid $gray-border;
  margin-top: 20px;
  padding-top: 10px;

  h3 {
    font-size: 23px;
    color: $gray-dark;
    font-weight: $font-weight-bolder;
    line-height: 1.3;
    text-align: center;
    margin: 0;
    small {
      display: block;
      color: $gray-dark;
      font-weight: $font-weight-base;
      font-size: 16px;
      line-height: 1.3;
      text-align: left;
      margin: 10px 0 0;
      &.center {
        text-align: center;
      }
      &.rtl {
        text-align: right;
        line-height: 1.8;
      }
    }
  }
  &.wrong {
    h3 {
      color: $oxford-blue;
    }
  }
  &.correct {
    h3 {
      color: $blue-jeans;
    }
  }
}

.pagination-wrapper {
  margin: 20px 0 0;
  position: absolute;
  left: 5px;
  right: 22px;
  bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0 -22px 0 -5px;

  @media (max-width: 1110px) {
    right: 40px;
  }

  .pagination {
    position: relative;
    flex: 1;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 50px;

    span {
      position: relative;
      width: 11px;
      height: 11px;
      border-radius: 50%;
      margin: 0 3px;
      border: 2px solid transparent;
      background: #ECF0F4;
      &:last-child {
        margin-right: 0;
      }

      &.previous {
        background-color: $blue-jeans;
      }

      &.active {
        width: 14px;
        height: 14px;
        border-color: $white;
        background-color: $blue-jeans;
        box-shadow: 0 0 0 2px  $blue-jeans;
      }
    }
  }

  .counter-state {
    color: $oxford-blue;
    font-size: 16px;
    line-height: 1;
    opacity: 1;
    width: 40px;
    text-align: right;
    position: absolute;
    right: 0;
    top: -2px;

    .rtl & {
      right: auto;
      left: 0;
      width: 30px;
    }
  }

}

@media (max-width: 989px) {
  .select-item {
    font-size: 15px;

    li {

      span {
        padding: 15px 10px 15px 44px;
        background-size: 18px;

        &.wrong {
          background-size: 18px;
        }
        &.correct {
          background-size: 18px;
        }
      }
    }
  }

  .rtl {
    .select-item {
      li {
        span {
          padding: 15px 44px 15px 10px;
        }
      }
    }
  }

  .pagination-wrapper {
    bottom: 0;
  }
}
